





























import { Vue, Component } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { SaveEstacaoUseCase } from '@/usecases'

@Component

export default class DialogoDeEdicaoDeEstacao extends Vue {
	estacao = {id:"", nome:""}
	mostra = false
	salvando = false
	saveEstacaoUseCase = new SaveEstacaoUseCase()

	mostrar(estacao) {
		this.mostra = true
		this.estacao = estacao
	}

	esconder() {
		this.mostra = false
		this.$emit('fecharDialogo')
	}

	async salvar() {
		if (!this.estacao) return
		try {
			this.salvando = true

			const estacao = this.estacao.id
				? await this.saveEstacaoUseCase.update(this.estacao)
				: await this.saveEstacaoUseCase.create(this.estacao)

			this.$emit('confirmado', estacao)
			this.mostra = false
			AlertModule.setSuccess('Estacao salva com sucesso')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}
}
